import { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import axios from 'axios'

import { FormField } from 'src/components'

import EnrollmentFormWrapper from './EnrollmentFormWrapper'
import EnrollmentFormSuccessBlock from './EnrollmentFormSuccessBlock'

import formData from 'src/formData.json'

import { IField } from 'src/interfaces'
import { userEmailContext } from '../../helpers/context'
import { API_HOST } from '../../helpers'

interface EnrollmentFormProps {
  userId?: string
}

const EnrollmentForm = (props: EnrollmentFormProps) => {
  const { userId } = props
  const [enrFormRes, setEnrFormRes] = useState<any>(null)
  const [error, setError] = useState<string | null>(null)

  const [email, setEmail] = useState<any>(null)

  const formik = useFormik<any>({
    initialValues: {
      id: localStorage.getItem('personId') || null,
      dateOfBirth: localStorage.getItem('dateOfBirth') || null,
      first_name: localStorage.getItem('first_name') || null,
      last_name: localStorage.getItem('last_name') || null,
      email: localStorage.getItem('email') || null,
      phoneNumber: '',
    },
    onSubmit: async (values, { setFieldValue: any }) => {
      console.log(userId, values, email)
      setError(null)
      axios
        .put(
          `${API_HOST}v1/forms`,
          {
            templateId: '29d6b0c8-2b9b-4171-bf2f-f4bbf8071252',
            payload: {
              steps: [
                {
                  fields: [
                    {
                      name: 'id',
                      input: 'basic',
                      label: 'Person ID',
                      value: values.id,
                    },
                    {
                      name: 'first_name',
                      input: 'basic',
                      label: 'first_name',
                      value: values.first_name,
                    },

                    {
                      name: 'last_name',
                      input: 'basic',
                      label: 'last_name',
                      value: values.last_name,
                    },

                    {
                      name: 'dateOfBirth',
                      input: 'basic',
                      label: 'dateOfBirth',
                      value: values.dateOfBirth,
                    },
                    {
                      name: 'duMakeCall',
                      input: 'basic',
                      label: 'duMakeCall',
                      value: values.duMakeCall,
                    },
                    {
                      name: 'duTimeToCall',
                      input: 'basic',
                      label: 'duTimeToCall',
                      value: values.duTimeToCall,
                    },
                    {
                      name: 'haveHealthInsurance',
                      input: 'basic',
                      label: 'haveHealthInsurance',
                      value: values.haveHealthInsurance,
                    },
                    {
                      name: 'email',
                      input: 'basic',
                      label: 'email',
                      value: values.email,
                    },
                    {
                      name: 'phoneNumber',
                      input: 'basic',
                      label: 'phoneNumber',
                      value: values.phoneNumber,
                    },
                  ],
                },
              ],
            },
            isCompleted: true,
            isReviewed: false,
            version: '1',
            email: values.email,
            personId: values.id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then((response) => {
          console.log(response)
          setEnrFormRes(response?.data)
        })
        .catch((err) => {
          console.log(err)
          setError('Something went wrong, try again')
        })
    },
  })

  return (
    <>
      {!!!enrFormRes ? (
        <userEmailContext.Provider value={{ email, setEmail }}>
          <FormikProvider value={formik}>
            <EnrollmentFormWrapper
              onNext={(v: any) => {
                formik.submitForm()
              }}
              error={error}
            >
              <>
                {(formData.steps[1].fields as IField[])
                  .filter((f: IField) => !f.disabled)
                  .map((f: IField) => (
                    <FormField key={f.name} {...f} onChange={(value) => {}} />
                  ))}
              </>
            </EnrollmentFormWrapper>
          </FormikProvider>
        </userEmailContext.Provider>
      ) : (
        <EnrollmentFormSuccessBlock />
      )}
    </>
  )
}

export default EnrollmentForm
