import { useEffect } from 'react'
import { useFormik } from 'formik'
import GatedPageTemplate from './templates/GatedPageTemplate/GatedPageTemplate'
import { BasicTemplate } from './templates'
import { useTranslation } from 'react-i18next'
import { isValid } from 'all-iso-language-codes'

import { DevControls } from './core'

import { isDevEnv } from './helpers'

function App() {
  const { t, i18n } = useTranslation()

  const _DEV_formikControls = useFormik({
    initialValues: {
      showPrivateBenefits: false,
      currentLanguage: 'en',
    },
    onSubmit: () => {
    },
  })


  useEffect(() => {
    // Detect language from subdomain
    let subDomainLanguage = detectSubDomainLanguage()
    //subDomainLanguage='es';
    if (subDomainLanguage) {
      i18n.changeLanguage(subDomainLanguage).then(() => {
        console.log('language set')
      }).catch(() => {
        console.log('error set')
      })
    }
  }, [])

  useEffect(() => {
    if (isDevEnv) {
      i18n.changeLanguage(_DEV_formikControls.values.currentLanguage)
    }
  }, [_DEV_formikControls.values.currentLanguage])

  function detectSubDomainLanguage() {
    const regexp = /\/\/(.*)\..*\..*/
    let res = document.location.href.match(regexp)
    console.log(!!res && isValid(res[1])
      ? res[1]
      : null)
    return !!res && isValid(res[1])
      ? res[1]
      : null
  }

  const showGatedPage = document.location.href.includes('/sign_in') || (isDevEnv && _DEV_formikControls.values.showPrivateBenefits)

  if (showGatedPage) {
    return (
      <>
        {isDevEnv ?
          <DevControls formik={_DEV_formikControls} />
          : null}
        <GatedPageTemplate />
      </>
    )
  } else {
    return (
      <>
        {isDevEnv ?
          <DevControls formik={_DEV_formikControls} />
          : null}
        <BasicTemplate />
      </>
    )
  }

}

export default App
