import styles from './enrollment-form-wrapper.module.scss'
import {Button} from 'src/components'
import {Trans} from "react-i18next";

const EnrollmentFormWrapper = (props: { onNext: any, error: string } | any) => {

    return (
        <div className={styles.wrapper}><div className={styles.enrollmentFormMargin}>{props.children}</div>
            {!!props.error ? <p style={{color:"red"}}>{props.error}</p> : null
            }
            <div className={styles.buttonContainer}>
                <Button
                    className={styles.button}
                    iconAfter="Arrow"
                    onClick={props.onNext}
                ><Trans>SUBMIT</Trans></Button>
            </div>
        </div>
    )
}

export default EnrollmentFormWrapper
