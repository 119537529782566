import { Select, DateInput, Input, PhoneInput } from 'src/components'

import { IField } from 'src/interfaces'

const FormField = (props: IField) => {
  let Component = null

  switch (props.input) {
    case 'select':
      Component = Select
      break
    case 'stateSelect':
      Component = Select
      break
    case 'checks':
      Component = Select
      break
    case 'phone':
      Component = PhoneInput
      break
    case 'date':
      Component = DateInput
      break
    case 'googlePlaces':
      Component = Select
      break
    default:
      Component = Input
  }

  if (!Component) {
    return null
  }

  return <Component {...props} />
}

export default FormField
