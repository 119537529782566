import { PageHeader} from "src/components"
import PageHeaderSimplified from "src/components/PageHeaderSimplified/PageHeaderSimplified"
import BenefitsListSimplified from "../BenefitsListSimplified/BenefitsListSimplified"

import styles from './basic-template.module.scss'


const BasicTemplate = ( ) => {

  return (
    <div className={styles.wrapper}>
        <PageHeaderSimplified></PageHeaderSimplified>
        <BenefitsListSimplified></BenefitsListSimplified>
      <div style={{paddingTop:50}}>
      </div>
    </div>
  )
}

export default BasicTemplate
