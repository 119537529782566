import { useTranslation } from 'react-i18next'

import { ButtonSimplified, ButtonWhiteSimplified } from 'src/components'

import { getApiLangItem } from 'src/helpers'

import styles from './benefits-list-simplified-card-buttons.module.scss'

import { IBenefitButtons } from 'src/interfaces'

interface BenefitsListSimplifiedCardButtonsProps {
    data: IBenefitButtons
}

const BenefitsListSimplifiedCardButtons = (props: BenefitsListSimplifiedCardButtonsProps) => {
  const { data } = props

  const { t, i18n } = useTranslation()

  const currentLanguageData = getApiLangItem(data, i18n.language)

  const button1 = {
    link: currentLanguageData[0].link || 'https://www.driversunionwa.org/sign_up',
    _target: currentLanguageData[0].useNewTab ? '_blank' : '_self',
    label: currentLanguageData[0].name || t('ENROLL IN YOUR FREE TRIAL')
  }

  const button2 = {
    link: currentLanguageData[1].link || 'https://www.driversunionwa.org/sign_in',
    _target: currentLanguageData[1].useNewTab ? '_blank' : '_self',
    label: currentLanguageData[1].name || t('ACCESS YOUR BENEFITS')
  }

  return (
    <div className={styles.wrapper}>
            {/* TODO mb replace with links */}
            
            <ButtonSimplified
                onClick={() => window.open(button2.link, button2._target, 'noopener,noreferrer')}
            >{button2.label}</ButtonSimplified>
      
            <ButtonWhiteSimplified
                onClick={() => window.open(button1.link, button1._target, 'noopener,noreferrer')}
            >{button1.label}</ButtonWhiteSimplified>
        </div>
  )
}

export default BenefitsListSimplifiedCardButtons
