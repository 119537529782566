import { useState, useEffect } from 'react'
import axios from 'axios'

import BenefitsListSimplifiedCard from './BenefitsListSimplifiedCard'

import { API_HOST, sortBenefitsByOrderProp } from 'src/helpers'

import styles from './benefits-list-simplified.module.scss'

import { IBenefit } from 'src/interfaces'

const BenefitsListSimplified = () => {
    const [benefitsFromApi, setBenefitsFromApi] = useState<IBenefit[]>([]);

    //TODO mb move it to react-query hook
    useEffect(() => {
        (async () => {
            const response = await axios.get(`${API_HOST}cms`);
            if (response?.data){
                setBenefitsFromApi(response?.data as IBenefit[]);
            }
        })()
    }, [])

  return (
    <div className={styles.wrapper}>
        {benefitsFromApi
        .sort(sortBenefitsByOrderProp)
        .map((benefit: IBenefit, i) => (
            <BenefitsListSimplifiedCard key={i} {...benefit} />
        ))}
    </div>
  )
}

export default BenefitsListSimplified
