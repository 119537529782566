import cx from 'classnames'
import Spinner from '../Spinner/Spinner'

import styles from './buttonWhite-simplified.module.scss'
import React from "react";
import { Icons } from '..';

interface ButtonProps {
  className?: string
  loading?: boolean
  iconAfter?: string
  onClick?: () => void
}

const ButtonWhiteSimplified = (props: React.PropsWithChildren<ButtonProps>) => {
  const {
    className,
    loading,
    iconAfter,
    onClick = () => false,
    children,
  } = props

   const IconAfterComponent =
    iconAfter &&
    (Object.entries(Icons)?.find(([key, value]) => key === iconAfter)?.[1] ??
      null)

  return (
    <button
      className={cx(styles.button, className)}
      disabled={loading}
      onClick={onClick}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          {children}

          {IconAfterComponent ? (
            <span className={styles.iconAfter}>
              <IconAfterComponent />
            </span>
          ) : null}
        </>
      )}
    </button>
  )
}

export default ButtonWhiteSimplified
