import { useQuery } from 'react-query'
//import { api } from 'src/api'

import { SYSTEM_MESSAGES } from './config'

const useGetSystemMessages = () =>
  useQuery(['systemMessages'], async () => {
    //TODO implement real endpoint flow
    //await api.get('/system-messages')

    return Promise.resolve({
      messages: SYSTEM_MESSAGES,
    })
  })

export default useGetSystemMessages
