import { useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import * as ShortCodes from 'src/shortcodes'

import { BenefitItem, ActionButton } from 'src/components'

import { getApiLangItem } from 'src/helpers'

import styles from './benefits-list-card.module.scss'

import { BenefitsListProps } from '../BenefitsList'
import { IBenefit } from 'src/interfaces'

const getHtmlPart = (item: string, passedProps: BenefitsListProps) => {
  const ShortCodeComponent = Object.entries(ShortCodes).find(([key]) => key === item)?.[1] ?? null

  if (ShortCodeComponent) {
    return <ShortCodeComponent {...passedProps} />
  }

  return <div dangerouslySetInnerHTML={{ __html: item }} />
}

interface BenefitsListCardProps {
  data: IBenefit
  passedProps: BenefitsListProps
}

const BenefitsListCard = (props: BenefitsListCardProps) => {
  const { data, passedProps } = props
  const { icons, metaData, content } = data

  const { i18n, t } = useTranslation()

  const [isOpened, setIsOpened] = useState<boolean>(false);

  //TODO mb refactor for getting array of components
  const htmlArray = (() => {
    const rawHtml = getApiLangItem(content.private, i18n.language)

    return rawHtml.split(/\[\[component:([^\]]+)\]\]/g)
  })()

  return (
    <div className={cx(styles.wrapper, isOpened && styles.active)}>
      <div className={styles.cardWrapper}>
        <BenefitItem icon={icons.private} title={getApiLangItem(metaData.name, i18n.language)} />
      </div>

      <div className={styles.inner}>
        <div className={styles.actionElement}>
          <ActionButton onClick={() => setIsOpened(!isOpened)} />
          <h4 className={styles.title}>{t('How to Use This Benefit')}</h4>
        </div>

        {isOpened ?
          <div className={styles.content}>
            {htmlArray.map((item: string, i: number) => (
              <Fragment key={i}>
                {getHtmlPart(item, passedProps)}
              </Fragment>
            ))}
          </div>
          : null}
      </div>
    </div>
  )
}

export default BenefitsListCard
