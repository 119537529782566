import { Trans } from 'react-i18next'
import styles from './page-header-simplified.module.scss'

const PageHeaderSimplified = (props: any) => {

    return (<div className={styles
            .wrapper}>
            <h2  style={{textAlign: "center"}} className={styles.firstHeader}><Trans>Drivers Union Benefits</Trans></h2>
           </div>
    )
}

export default PageHeaderSimplified
