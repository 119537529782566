import { useState } from "react";

import cx from 'classnames'

import styles from './action-button.module.scss'


interface ActionButtonProps {
  value?: boolean;
  onClick:any;

}
const ActionButton = (props:ActionButtonProps) => {

  const [isButtonClicked, setIsButtonClicked] = useState<boolean>(props.value || false);

  return <div className={cx(styles.buttonContainer )} onClick={() => {props.onClick(); return setIsButtonClicked(!isButtonClicked)}}>
    <img style={{width: 20, height: 20}}  src={process.env.REACT_APP_IMG_URLS+'/plus.png'} className={isButtonClicked ? styles.clicked : ""}/>
  </div>
}

export default ActionButton
