import { useTranslation } from 'react-i18next'

import { BenefitItemSimplified } from 'src/components'

import BenefitsListSimplifiedCardButtons from './BenefitsListSimplifiedCardButtons'

import { getApiLangItem } from 'src/helpers'

import styles from './benefits-list-simplified-card.module.scss'

import { IBenefit } from 'src/interfaces'

const BenefitsListSimplifiedCard = (props: IBenefit) => {
    const { icons, metaData, content, buttons } = props

    const { i18n } = useTranslation()

  return (
    <div className={styles.wrapper}>
        <div>
            <BenefitItemSimplified icon={icons.public} /> {/* TODO rename this common component */}
        </div>

        <div className={styles.contentWrapper}>
            <h4 className={styles.title}>{getApiLangItem(metaData.name, i18n.language)}</h4>

            <div className={styles.content} dangerouslySetInnerHTML={{__html: getApiLangItem(content.public, i18n.language)}} />
        </div>

        <BenefitsListSimplifiedCardButtons data={buttons} />
    </div>
  )
}

export default BenefitsListSimplifiedCard
