import { useState, useEffect } from 'react'
import axios from 'axios'

import BenefitsListCard from './BenefitsListCard'

import { API_HOST, sortBenefitsByOrderProp } from 'src/helpers'

import styles from './benefits-list.module.scss'

import { IBenefit } from 'src/interfaces'

export interface BenefitsListProps {
    userId?: string
}

const BenefitsList = (props: BenefitsListProps) => {
    const [benefitsFromApi, setBenefitsFromApi] = useState<IBenefit[]>([]);

    //TODO mb move it to react-query hook
    useEffect(() => {
        (async () => {
            const response = await axios.get(`${API_HOST}cms`);
            if (response?.data){
                setBenefitsFromApi(response?.data as IBenefit[]);
            }
        })()
    }, [])

  return (
    <div className={styles.wrapper}>
        {benefitsFromApi
        .sort(sortBenefitsByOrderProp)
        .map((benefit: IBenefit, i) => (
            <BenefitsListCard key={i} data={benefit} passedProps={props} />
        ))}
    </div>
  )
}

export default BenefitsList