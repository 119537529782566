import React, {useState} from "react";

import cx from 'classnames'

import styles from './benefit-item.module.scss'


interface BenefitItemProps {
    icon: string;
    title: string;

}

const BenefitItem = (props: React.PropsWithChildren<BenefitItemProps>) => {
    return (
        <div className={styles.container}>
            <img src={props.icon} />
            <p className={styles.paragraphSubtitle}>
                {props.title}
            </p>
        </div>)
}

export default BenefitItem
