import { IBenefit } from 'src/interfaces'

export const sortBenefitsByOrderProp = (a: IBenefit, b: IBenefit) => {
    if(typeof a.metaData.order === 'undefined' && typeof b.metaData.order === 'undefined') {
      return 0;
    }
    
    if(typeof a.metaData.order === 'undefined') {
      return 1;
    }
    if(typeof b.metaData.order === 'undefined') {
      return -1;
    }
  
    return a.metaData.order - b.metaData.order;
  }