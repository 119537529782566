import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import * as langFiles from './resources'

console.log(langFiles);

const resources = Object.entries(langFiles).reduce((a, [key, file]) => {
  a[key] = {
    translation: file,
  }
  return a
}, {} as any)
console.log('resources',resources);
i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  debug: true,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
