import styles from './login-form.module.scss'
import {Button} from "../../../components";
import cx from "classnames";
import { Trans } from 'react-i18next';

import SystemMessages from './SystemMessages'

const LoginForm = (props: { onNext: any, error: string, user: any, isUserInactive: boolean } | any) => {
    return (
        <div className={styles.wrapper}>
            <h2 className={styles.title}><Trans>Login to Your Drivers Union Benefits</Trans></h2>
            <p className={styles.subTitle}><Trans>Access Drivers Union Benefits by inputting your email address
                below.</Trans></p>

            <SystemMessages />

            <div className={styles.childrenContainer}>
                {props.children}
            </div>
            {(!!props.error) ? (
                <div className={styles.expirationContainer}>
                    <div className={styles.errorContainer}><p><Trans>Your Email Address doesn’t match our records. Please try again.</Trans></p></div>
                    <br></br>

                    <div className={cx(styles.buttonContainer,styles.mobileButton )}>
                        <Button
                            className={styles.button}
                            iconAfter="Arrow"
                            onClick={props.onNext}
                        ><Trans>Sign In</Trans></Button>
                    </div>
                    <p  className={styles.orText}><Trans>- OR -</Trans></p>
                    <h3 className={styles.coloredTextHeader}><Trans>Please click the link below to begin your 90-Day Trial
                        Membership, or call us for assistance.</Trans></h3>
                    <div className={cx(styles.buttonContainer,styles.mobileButton )}>
                        <Button
                            className={styles.button}
                            iconAfter="Arrow"
                            onClick={() => {
                                window.open('https://www.driversunionwa.org/sign_up', '_blank', 'noopener,noreferrer');
                            }}
                        ><Trans>Join Us</Trans></Button>
                    </div>
                </div>

            ) : null}
            {(!!props.isUserInactive || false) ? (
                <div className={styles.expirationContainer}>
                    <div className={styles.errorContainer}><p><Trans>Your Membership with Drivers Union has
                        expired.</Trans></p></div>
                    <h3 className={styles.coloredTextHeader}><Trans>don’t worry! Joining as a dues-paying member is
                        quick and easy. Just click the button below, or call us.</Trans></h3>
                    <div className={styles.buttonConatiner}>
                        <Button
                            className={styles.button}
                            iconAfter="Arrow"
                            onClick={() => {
                                window.open('https://www.driversunionwa.org/join_du', '_blank', 'noopener,noreferrer');
                            }}
                        ><Trans>Join Us</Trans></Button>
                    </div>
                    <p className={styles.subText}><Trans>Dues are as low as $0.13/trip and can be canceled at
                        any time.</Trans></p>
                </div>

            ) : null}
            {!(!!props.error) && !(!!props.isUserInactive || false) ? (
                <div className={cx(styles.buttonContainer,styles.mobileButton )}>
                    <Button
                        className={styles.button}
                        iconAfter="Arrow"
                        onClick={props.onNext}
                    ><Trans>Sign In</Trans></Button>
                </div>
            ) : null}
            <div className={styles.devider}/>
            <div className={styles.footerTextContainer}>
                <p className={styles.coloredText}><span><Trans>Need help?</Trans></span></p>
                <p className={styles.coloredText}><Trans>Call us at <span> <a
                    href="tel:844-998-4559">844-998-4559</a></span> (Monday - Friday 8AM - 6PM)</Trans></p>

            </div>
        </div>
    )
}

export default LoginForm
