import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import cx from 'classnames'

import { FieldWrapper } from 'src/components'

import styles from './input.module.scss'

import { IField } from 'src/interfaces'

const Input = (props: IField) => {
  const { name, placeholder } = props

  const { t } = useTranslation()

  const [dribbleError, setDribbleError] = useState<boolean>(false)

  const [field, meta] = useField({ name })

  //TODO dribble error effect
  // useEffect(() => {
  //   if (meta.touched && !dribbleError){
  //     setDribbleError(true)
  //   }
  // }, [meta.touched])

  return (
    <FieldWrapper {...props}>
      <input
        className={cx(
          styles.basicInput,
          meta.error && meta.touched && styles.error,
          dribbleError && styles.dribbleError,
        )}
        placeholder={placeholder ? `${t(placeholder)}` : undefined}
        {...field}
      />
    </FieldWrapper>
  )
}

export default Input
