
import cx from 'classnames'

import styles from './enrollment-form-success-block.module.scss'
import {useTranslation} from "react-i18next";


const EnrollmentFormSuccessBlock = () => {
  const { t, i18n } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div >
         {/* <img src={process.env.REACT_APP_IMG_URLS +'/appstore.png'} />*/}
        <img className={styles.containerImg} src={process.env.REACT_APP_IMG_URLS +'/thankyou.png'} />
        </div>
        <div className={cx(styles.basicItalicBoldHuge, styles.marginTen)}>
          {t('THANK YOU!')}
        </div>
        <div className={cx(styles.marginDouble, styles.basicComment, styles.thankYouSubtitle)}>
          {t('We have received your information. We will be in touch soon!')}
        </div>
        <div className={styles.containerButtons}>
        </div>
      </div>
    </div>
  )
}

export default EnrollmentFormSuccessBlock
