import { FormikProvider } from 'formik'

import { Button, Select } from 'src/components'

import styles from './dev-controls.module.scss'

import { FormikContextType } from 'formik'

export const LANG_OPTIONS = [
  { value: 'en', label: 'English' },
  { value: 'so', label: 'Somali' },
  { value: 'am', label: 'Amharic' },
  { value: 'ar', label: 'Arabic' },
  { value: 'om', label: 'Oromo' },
  { value: 'pa', label: 'Punjabi' },
  { value: 'vi', label: 'Vietnamese' },
  { value: 'fr', label: 'French' },
  { value: 'es', label: 'Spanish' },
  { value: 'zh', label: 'Chinese (simplified)' },
]

interface DevControlsProps {
  formik: FormikContextType<any>
}

const DevControls = (props: DevControlsProps) => {
  const { formik } = props

  const handleSwitchMode = () => {
    formik.setFieldValue('showPrivateBenefits', !formik.values.showPrivateBenefits)
  }

  return (
    <div className={styles.wrapper}>
      <FormikProvider value={formik}>
        <Button onClick={handleSwitchMode}>{`Switch to ${formik.values.showPrivateBenefits ? 'public' : 'private'}`}</Button>

        <Select name="currentLanguage" input="select" options={LANG_OPTIONS} />
      </FormikProvider>
    </div>
  )
}

export default DevControls